.App {
  text-align: center;
}

.card {
  background: #b0b0b0;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  transition: transform 0.3s, box-shadow 0.3s;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 30px rgba(0, 0, 0, 0.15);
}

.cardDisabled {
  background-color: #b0b0b0;
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 1.2em;
  font-weight: bold;
}

.cardContent {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  color: white;
  font-size: 1.1em;
  font-weight: bold;
  text-align: center;
}
header{
  background: #ffffff;
  min-height: 25vh;
}